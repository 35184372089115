import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  industryTab: "all",
  endUsersTab: "all",
  expertsTab: "all",
};

const tabStatusSlice = createSlice({
  name: "tabStatus",
  initialState,
  reducers: {
    setIndustryTab: (state, action) => {
      state.industryTab = action.payload;
    },
    setEndUsersTab: (state, action) => {
      state.endUsersTab = action.payload;
    },
    setExpertsTab: (state, action) => {
      state.expertsTab = action.payload;
    },
  },
});

export const { setIndustryTab, setEndUsersTab, setExpertsTab } =
  tabStatusSlice.actions;

export default tabStatusSlice.reducer;
