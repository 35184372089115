import CryptoJS from "crypto-js";

const CRYPTO_SECRET_KEY = process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY;

const useCrypto = () => {
  const encodeData = (data) => {
    return new Promise((resolve) => {
      try {
        const stringyfyData = JSON.stringify(data);
        const encryptedData = CryptoJS.AES.encrypt(
          stringyfyData,
          CRYPTO_SECRET_KEY
        ).toString();
        
        return resolve({
          success: true,
          data: encryptedData,
        });
      } catch (err) {
        console.log("Encryption Error :- ", err);
        return resolve({
          success: false,
          data: null,
        });
      }
    });
  };
  const decodeData = (encodedData) => {
    return new Promise((resolve) => {
      try {
        const decrptedBytes = CryptoJS.AES.decrypt(
          encodedData,
          CRYPTO_SECRET_KEY
        );
        const decryptedString = decrptedBytes.toString(CryptoJS.enc.Utf8);
        if (!decryptedString) {
          console.log("Error To Get Decrypted String...");
          return resolve({
            success: false,
            data: null,
          });
        }
        const decodedData = JSON.parse(decryptedString);
        return resolve({
          success: true,
          data: decodedData,
        });
      } catch (err) {
        console.log("Decryption Error :- ", err);
        return resolve({
          success: false,
          data: null,
        });
      }
    });
  };
  return { encodeData, decodeData };
};

export default useCrypto;
