// App.js
import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Theme from "components/template/Theme";
import Layout from "components/layout";
import history from "./history";
import {
  requestNotificationPermission,
  handleIncomingNotifications,
  handleTokenRefresh
} from "./helper/notificationService.js";

function App() {
  useEffect(() => {
    // Request notification permission and handle token retrieval
    requestNotificationPermission();

    // Handle incoming notifications (both foreground and background)
    handleIncomingNotifications();

    // Handle token refresh events
    handleTokenRefresh();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <Theme>
            <Layout />
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
