// ------- Auth Token ------------------------------------------------------------
export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";

// ------- Project mode -----------------------------------------------------------------
export const REACT_APP_PROJECT_MODE = process.env.REACT_APP_PROJECT_MODE;

// ------- Dev URL -----------------------------------------------------------------
export const REACT_APP_BACKEND_DEV_URL = process.env.REACT_APP_BACKEND_DEV_URL;

// ------- Stage URL ---------------------------------------------------------------
export const REACT_APP_BACKEND_STAGE_URL =
  process.env.REACT_APP_BACKEND_STAGE_URL;

// ------- Prod URL -----------------------------------------------------------------
export const REACT_APP_BACKEND_PROD_URL =
  process.env.REACT_APP_BACKEND_PROD_URL;

export let BACKEND_SERVER_URL;

switch (REACT_APP_PROJECT_MODE) {
  case "development":
    BACKEND_SERVER_URL = REACT_APP_BACKEND_DEV_URL;
    break;
  case "stage":
    BACKEND_SERVER_URL = REACT_APP_BACKEND_STAGE_URL;
    break;
  case "production":
    BACKEND_SERVER_URL = REACT_APP_BACKEND_PROD_URL;
    break;
  default:
    BACKEND_SERVER_URL = "";
    break;
}
