import axios from "axios";
import {
  BACKEND_SERVER_URL,
  REACT_APP_PROJECT_MODE,
} from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import useCrypto from "utils/hooks/useCrypto";

const api = axios.create({
  baseURL: `${BACKEND_SERVER_URL}`,
});

// Add a request interceptor
api.interceptors.request.use(
  async function (config) {
    const { encodeData } = useCrypto();

    if (REACT_APP_PROJECT_MODE === "production") {
      const encryptedBody = await encodeData(config.data);

      if (!encryptedBody.success) {
        console.error("Failed To Encrypt Config Body...");
        return Promise.reject("Failed To Encrypt Config Body...");
      }

      config.data = { data: encryptedBody.data };
    }

    if (config.url === "/api/auth/admin/login") {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
    } else {
      const user = localStorage.getItem(PERSIST_STORE_NAME);
      const persistData = deepParseJson(user);
      const token = persistData?.auth?.user?.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["Content-Type"] = "multipart/form-data";
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  async function (response) {
    const { decodeData } = useCrypto();

    if (REACT_APP_PROJECT_MODE === "production") {
      const decodedResponse = await decodeData(response.data);

      if (!decodedResponse.success) {
        console.error("Failed To Decode Response Data...");
        return Promise.reject("Failed To Decode Response Data...");
      }

      response.data = decodedResponse.data;

      return response.data;
    }
    // Do something with response data
    const result = response.data;
    if (Object.keys(result).includes("isAuth")) {
      const isAuth =
        typeof result?.isAuth === "string"
          ? result === "false"
          : !result?.isAuth;

      if (isAuth) {
        localStorage.clear();
        window.location.reload();
      }
    }
    return result;
  },
  function (error) {
    // const serverResponse = error.response.data;
    if (error?.response?.data?.isAuth === false) {
      // const isAuth =
      //   typeof serverResponse?.isAuth === "string"
      //     ? serverResponse === "false"
      //     : !serverResponse?.isAuth;

      // if (isAuth) {
      localStorage.clear();
      window.location.reload();
      // }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
