import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  categoryPagination: {},
  requestedAdsPagination: {},
  adBannerImagesPagination: {},
};

export const configurationSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {
    setCategoryPagination: (state, action) => {
      state.categoryPagination = action.payload;
    },
    setRequestedAdsPagination: (state, action) => {
      state.requestedAdsPagination = action.payload;
    },
    setAdBannerImagesPagination: (state, action) => {
      state.adBannerImagesPagination = action.payload;
    },
  },
});

export const {
  setCategoryPagination,
  setRequestedAdsPagination,
  setAdBannerImagesPagination,
} = configurationSlice.actions;

export default configurationSlice.reducer;
