const { createSlice } = require("@reduxjs/toolkit");

export const initialState = {
  offerPagination: {},
};

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    setOfferPagination: (state, action) => {
      state.offerPagination = action.payload;
    },
  },
});

export const { setOfferPagination } = offerSlice.actions;

export default offerSlice.reducer;
