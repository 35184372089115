/* eslint-disable no-restricted-globals */
// notificationService.js
import { getMessaging, getToken, onMessage } from "firebase/messaging"; // Import functions from firebase/messaging
import { messaging } from "../firebase"; // Get the messaging instance from firebase.js
import { sendDeviceToken } from "service/deviceTokenServices";

// Request notification permission and get the token
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");

      // Get the FCM token for the client
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY, // Use VAPID key if needed
      });

      if (token) {
        console.log("FCM Token:", token);

        // Check if the token needs to be sent to the server
        await saveTokenIfNecessary(token);
      }
    } else {
      console.error("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

// Save the token to the server if it hasn't been sent yet
const saveTokenIfNecessary = async (token) => {
  try {
    const storedToken = localStorage.getItem("fcmToken");

    // If the token is new or has changed, send it to the server
    if (storedToken !== token) {
      console.log("New or updated FCM token. Sending to the server...");
      await sendTokenToServer(token);

      // Store the token in localStorage after sending to the server
      localStorage.setItem("fcmToken", token);
    } else {
      console.log("FCM token is already up-to-date.");
    }
  } catch (error) {
    console.error("Error saving FCM token:", error);
  }
};

// Send the token to the server
export const sendTokenToServer = async (token) => {
  try {
    // Check if deviceId already exists in localStorage
    let deviceId = localStorage.getItem("deviceId");

    if (!deviceId) {
      // Generate a new deviceId if it doesn't exist
      deviceId = self.crypto.randomUUID().replace(/-/g, ""); // Generate unique ID
      localStorage.setItem("deviceId", deviceId); // Save it in localStorage
    }

    console.log("deviceId:", deviceId);

    // Send the device token and deviceId to the server
    const response = await sendDeviceToken({ deviceId, deviceToken: token });

    if (response.ok) {
      console.log("FCM token sent to server successfully.");
    } else {
      console.error(
        "Failed to send FCM token to server:",
        response.statusText || response.status
      );
    }
  } catch (error) {
    console.error("Error sending FCM token to server:", error);
  }
};

// Handle incoming foreground notifications
export const handleIncomingNotifications = () => {
  onMessage(messaging, (payload) => {
    console.log("Foreground message received:", payload);

    // Display a notification for incoming messages
    const { title, body, icon } = payload.notification;

    // Show notification using the Notification API
    new Notification(title || "New Message", {
      body: body || "You have a new notification.",
      icon: icon || "/default-icon.png",
    });
  });
};

// Manually refresh FCM token and handle token changes
export const handleTokenRefresh = async () => {
  try {
    // Fetch a new token (if refreshed or expired)
    const newToken = await getToken(messaging);

    if (newToken) {
      console.log("Token refreshed:", newToken);

      // Check if the token needs to be sent to the server
      await saveTokenIfNecessary(newToken);
    }
  } catch (error) {
    console.error("Error refreshing FCM token:", error);
  }
};
