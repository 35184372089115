import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  transactionPagination: {},
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setTransactionPagination: (state, action) => {
      state.transactionPagination = action.payload;
    },
  },
});

export const { setTransactionPagination } = transactionSlice.actions;

export default transactionSlice.reducer;