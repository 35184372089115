import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  faqsPagination: {},
  inquiriesPagination: {},
};

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setFaqsPagination: (state, action) => {
      state.faqsPagination = action.payload;
    },
    setInquiriesPagination: (state, action) => {
      state.inquiriesPagination = action.payload;
    },
  },
});

export const { setFaqsPagination, setInquiriesPagination } =
  supportSlice.actions;

export default supportSlice.reducer;
